<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-dark">
      <router-link to="/services" class="navbar-brand">SVCPUL Dashboard</router-link>
      <div class="navbar-nav mr-auto" v-if="$auth.isAuthenticated">
        <li class="nav-item">
          <router-link to="/services" class="nav-link">Services</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/add" class="nav-link">Add</router-link>
        </li>
        <li class="nav-item">
          <span style="cursor: pointer;" @click="logout" class="nav-link">Logout</span>
        </li>
      </div>
    </nav>

    <div class="container mt-3">
      <h2>SVCPUL Manage Services</h2>
     
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  methods: {
logout() { 
  this.$auth.logout(); 
  this.$router.push({ path: '/' }); 
} 
  }
};


</script>

<style scoped>
.container h2 {
  text-align: center;
  margin: 25px auto;
}
</style>